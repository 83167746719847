<template>
    <div class="login">
        <div class="content">
            <h2 class="title">查重树平台登陆</h2>
            <div class="content-login">
                <a-input class="userName" placeholder="用户名" v-model="userName">
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                </a-input>
                <div class="border" style="padding-top:30px"></div>
                <a-input class="password" type="password" placeholder="密码" v-model="password">
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                </a-input>
                <div class="border" style="padding-top:30px"></div>
                <a-button type="primary" @click="login()">登陆</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import cookie from "@/common/cookie";

export default {
    data() {
        return {
            userName: '',
            password: ''
        }
    },

    methods: {
        async login() {
            if (this.userName == '' || this.password == '') {
                this.$message.error('用户名或密码不能为空')
                return;
            }
            let url = `${this.weipu.proxyServiceURL}/chachong/login`

            let loginObj = {
                userName: this.userName,
                password: this.password,
            };

            let res = await axios.post(
                url,
                loginObj
            );

            if (res.data.code == -1) {
                this.$message.error(res.data.message)
                return;
            } else {
                let token = res.data.result.token;
                let number = res.data.result.number;
                cookie.setCookie("token", token, 7);
                cookie.setCookie("number",number,7);
                this.$router.push("/user/main");
            }

        }
    }
};

</script>

<style lang="less">
.login {
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url("../../assets/login.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .content {

        .title {
            font-size: 50px;
            padding-top: 50px;
            text-align: center;
            color: bisque;
        }

        .content-login {
            text-align: center;

            .userName {
                width: 500px;
            }

            .password {

                width: 500px;
            }
        }
    }
}
</style>