import cookie from '@/common/cookie'
import axios from 'axios'

export default {

    post(url, body, params) {
        const token = cookie.getCookie("token");
        return axios({
            url,
            method: "POST",
            headers: { token: token },
            params: params || {},
            data: body
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },

    postFormData(url, formData, params) {
        const token = cookie.getCookie("token");
        return axios({
            url,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data", token: token },
            params: params || {},
            data: formData,
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },

    get(url, params) {
        const token = cookie.getCookie("token");
        return axios({
            url,
            method: "GET",
            headers: {
                token: token
            },
            params: params || {}
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },

    getWithoutToken(url, params) {
        return axios({
            url,
            method: "GET",
            headers: {},
            params: params || {}
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },

    put(url, body, params) {
        const token = cookie.getCookie("token");
        return axios({
            url,
            method: "PUT",
            headers: { token: token },
            params: params || {},
            data: body
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },


    delete(url, body, params) {
        const token = cookie.getCookie("token");
        return axios({
            url,
            method: "DELETE",
            headers: { token: token },
            params: params || {},
            data: body
        }).then((res) => {
            return res.data
        }).catch(err => {
            this.$message.error(err)
            return;
        })
    },


}