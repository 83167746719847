export default {

    getLoginToken() {

        return this.getCookie('token')
    },

    getCookie(name) {
        let cookieString = "; " + document.cookie
        let parts = cookieString.split("; " + name + "=")

        if (parts.length == 2) {
            return parts.pop().split(";").shift()
        }

        return ''
    },

    setCookie(cname, cvalue, exdays, domain) {
        let expires = ''
        let d = new Date()

        exdays = exdays || 1
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))  //默认一天
        expires = "expires=" + d.toUTCString()

        if (typeof(domain)==="string") {

            domain = domain.substr(domain.indexOf('.'))
            document.cookie = cname + "=" + cvalue + "; " + expires + ';domain=' + domain + ';path=/'
        } else {

            document.cookie = cname + "=" + cvalue + "; " + expires
        }
    },

    delCookie(name, domain) {
        this.setCookie(name, "", -1, domain)
    }
}