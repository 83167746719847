<template>
<div class="logo-container">
    <div class="mobile-logo" ><img class="logo-mobile" src="@/assets/weipu.png" alt="" /></div>
</div>
</template>

<script>
export default {
    data(){
        return {
        }
    },
    mounted(){

    },
    created() {
        
    },
    methods:{
        
    }
}
</script>

<style lang="less">
.logo-container {
        .logo {
            height: 80px;
            text-align: center;
            background-image: url('~@/assets/navbar.jpeg');

            .logo-normal {
                height: 80px;
                text-align: center;
            }
        }
        .mobile-logo {
            height: 80px;
            text-align: center;
            .logo-mobile{
                height: 80px;
                text-align: center;
            }
        }
    }
</style>