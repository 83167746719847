<template>
    <div class="client-download">
        <Navbar v-show="mobile"/>
        <PcNavbar v-show="!mobile"/>
        <div :class="sizeClass">
            <div class="search">
                <a-input ref="userNameInput" v-model="inputOrderId" placeholder="淘宝商家提供的检测卡号">
                    <a-icon slot="prefix" type="taobao" />
                    <a-tooltip slot="suffix" title="输入后点击下方的搜索按钮即可查看结果并下载">
                        <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                    </a-tooltip>
                </a-input>
                <div class="search-button">
                    <a-button type="primary" icon="search" size="large" @click="onclickSearchBtn()">
                        搜索
                    </a-button>
                </div>
            </div>

            <div class="message">
                <span>上一次刷新时间：{{ refreshTime }}</span>
            </div>

            <a-descriptions class="result-table" title="" bordered>
                <a-descriptions-item label="订单号">
                    {{ orderId }}
                </a-descriptions-item>
                <a-descriptions-item label="论文标题">
                    {{ title }}
                </a-descriptions-item>
                <a-descriptions-item label="完成日期">
                    {{ resultDate }}
                </a-descriptions-item>
                <a-descriptions-item label="完成时间">
                    {{ resultTime }}
                </a-descriptions-item>
                <a-descriptions-item label="状态">
                    {{ status }}
                </a-descriptions-item>
            </a-descriptions>

            <div class="operation">
                <a-row class="download-operation">
                    <a-button type="primary" icon="download" size="large" @click="downloadFile()">
                        下载报告
                    </a-button>
                </a-row>
                <a-row class="delete-operation">
                    <a-button type="danger" icon="delete" size="large" @click="deleteOrder()">
                        删除报告
                    </a-button>
                </a-row>
                <a-row class="clear-operation">
                    <a-button icon="close" size="large" @click="reset()">
                        清空搜索
                    </a-button>
                </a-row>
            </div>
            
        </div>
        <GlobalFooter class="footer" />
    </div>
</template>

<script>
import Navbar from "@/components/file/header";
import PcNavbar from "@/components/file/pc-header";
import GlobalFooter from "@/components/file/footer"
import axios from "axios";
import axiosFactory from "@/models/axios-factory";

export default {
    components: {
        Navbar,
        GlobalFooter,
        PcNavbar
    },

    data() {
        return {
            inputOrderId: '',
            orderId: '',
            title: '',
            resultDate: '',
            resultTime: '',
            status: '',
            refreshTime: '',
            mobile: window.innerWidth <= 750 ? true : false
        }
    },

    computed:{
        sizeClass(){
            if(window.innerWidth<=750){
                return 'content'
            }else{
                return 'content-pc'
            }
        }
    },

    mounted() {
        this.render();
        this.scheduleSearch()
    },

    methods: {
        async render() {
            let sessionOrderId = sessionStorage.getItem('client-orderId-value')
            if (!sessionOrderId) {
                return;
            }
            this.inputOrderId = sessionOrderId;
            let url = `${this.weipu.serviceURL}/client/getOrderResult`;
            let data = await axiosFactory.getWithoutToken(url, { orderId: sessionOrderId })
            if (data.code == -1) {
                this.$message.error(data.message);
                return;
            }
            this.orderId = data.result.orderId
            this.title = data.result.title;
            this.resultDate = data.result.resultDate;
            this.resultTime = data.result.resultTime;
            this.status = this.getStatus(data.result.status, data.result.valid);
            if (this.status == "已完成") {
                this.$message.success("订单已完成，请点击‘下载报告’进行下载");
            } else if (this.status == "检测中") {
                this.$message.warn("订单检测中");
            }
            this.refreshTime = this.getCurrentDateTime();
        },

        async onclickSearchBtn() {
            if (this.inputOrderId == "") {
                this.$message.error("搜索不能为空")
                return;
            }
            let url = `${this.weipu.serviceURL}/client/getOrderResult`;
            let data = await axiosFactory.getWithoutToken(url, { orderId: this.inputOrderId })
            if (data.code == -1) {
                this.$message.error(data.message);
                return;
            }
            this.orderId = data.result.orderId
            this.title = data.result.title;
            this.resultDate = data.result.resultDate;
            this.resultTime = data.result.resultTime;
            this.status = this.getStatus(data.result.status);
            if (this.status == "已完成") {
                this.$message.success("订单已完成，请点击‘下载报告’进行下载");
                sessionStorage.setItem("client-orderId-value", this.orderId)
            } else if (this.status == "检测中") {
                this.$message.warn("订单检测中");
                sessionStorage.setItem("client-orderId-value", this.orderId)
            }
            this.refreshTime = this.getCurrentDateTime();
        },

        async downloadFile(){
            let data = await axiosFactory.getWithoutToken(`${this.weipu.serviceURL}/client/downloadResult`, 
            {orderId: this.inputOrderId})
            if(data.code ===-1){
                this.$message.error(data.message);
                return;
            }
            window.location.href = data.result.resultURL
        },

        async scheduleSearch() {
            while (true) {
                await this.delay(30000);
                let sessionOrderId = sessionStorage.getItem('client-orderId-value')
                if (sessionOrderId && this.status == "检测中") {
                    let url = `${this.weipu.serviceURL}/client/getOrderResult`;
                    let data = await axiosFactory.getWithoutToken(url, { orderId: sessionOrderId })
                    if (data.code == -1) {
                        this.$message.error(data.message);
                        return;
                    }
                    this.orderId = data.result.orderId
                    this.title = data.result.title;
                    this.resultDate = data.result.resultDate;
                    this.resultTime = data.result.resultTime;
                    this.status = this.getStatus(data.result.status);
                    if (this.status == "已完成") {
                        this.$message.success("订单已完成，请点击‘下载报告’进行下载");
                        break;
                    } else if (this.status == "检测中") {
                        this.$message.warn("订单检测中");
                    }
                    this.refreshTime = this.getCurrentDateTime();
                }
                await this.delay(30000);
            }
        },

        getCurrentDateTime() {
            return new Date().toLocaleTimeString()
        },

        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        getStatus(status) {
            switch (status) {
                case 0:
                    return "检测中";
                case 1:
                    return "已完成";
                case 2:
                    return "已删除"
            }
        },

        deleteOrder() {
            if (this.orderId == '' || this.status != "已完成") {
                this.$message.error("无法执行删除");
                return;
            }
            this.$confirm({
                title: '提示',
                content: '删除后将无法下载，请确认是否提交?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    let url = `${this.weipu.serviceURL}/client/deleteRecord`;;
                    axios({
                        url,
                        method: "DELETE",
                        headers: {},
                        params: { orderId: this.orderId },
                    }).then((res) => {
                        if (res.data.code == -1) {
                            this.$message.error(res.data.message)
                            return;
                        }
                        this.$message.success("删除成功")
                        //清空数据
                        this.reset();
                    });
                }
            })
        },

        reset() {
            sessionStorage.removeItem("client-orderId-value");
            this.inputOrderId = "";
            this.orderId = ''
            this.title = ''
            this.resultDate = '',
                this.resultTime = '',
                this.status = ''
        },

    }
}

</script>

<style lang="less">
.client-download {
    .content-pc {
        //padding-top: 20px;
        padding: 30px 116px 60px 116px;
    }

    .content{
        padding-top: 20px;
    }

    .search {
        .search-button {
            padding-top: 10px;
            padding-right: 20px;
            float: right;
        }
    }

    .message {
        padding-top: 20px;
    }

    .result-table {
        margin-top: 70px;
    }

    .operation {
        padding-top: 30px;
        padding-right: 20px;
        float: right;

        .delete-operation {
            padding-top: 20px;
        }

        .clear-operation {
            padding-top: 20px;
        }
    }

    .footer {
        bottom: 0;
        margin-top: 300px;
    }

}
</style>