<template>
    <div class="global-footer">
        <span class="copyright">
            <a class="text" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022082317号</a>
            <p class="copyright-text">Copyright © vipcheck {{year}} all right reserved.</p>
        </span>
    </div>
</template>

<script>
export default {
    data(){
        return{
            year:''
        }
    },

    mounted(){
        let date = new Date()
        this.year = date.getFullYear()
        //console.log(date.getFullYear())
    },

    methods:{

    }
}
</script>

<style lang="less">
.global-footer {
    width: 100%;
    bottom: 0;
    text-align: center;
    padding-top: 100px;

    .copyright {
        .text {

            color: #808080;
            font-size: 12px;
            text-decoration: underline !important;
        }

        .copyright-text {
            font-size: 12px;
            color: #808080;
        }
    }
}
</style>