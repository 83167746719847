<template>
    <div class="pc-client-upload">
        <Navbar />
        <div class="content">
            <SelectNavbar />
            <div class="content-title">上传论文</div>
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="orderId" prop="orderId">
                    <a-input addon-before="检测卡号:" v-model="form.orderId" placeholder="淘宝商家提供的检测卡号" />
                </a-form-model-item>
                <div class="add-card" @click="changeShowAdd">
                    <a>补拍点这里</a>
                </div>   
                <a-form-model-item class="addition-operation" v-show="showAdd">
                    <a-button @click="addAdditionalOrderId" icon="plus-circle" type="primary">添加补拍卡号</a-button>
                    <a-button @click="removeAdditionalOrderId" icon="minus-circle" type="primary" style="margin-left: 8px;">删除补拍卡号</a-button>
                </a-form-model-item>

                <!-- 动态添加的输入框 -->
                <a-form-model-item v-for="(id, index) in form.additionalOrderIds" :key="index">
                    <a-input addon-before="新增补拍卡号:" v-model="id.value" placeholder="请输入补拍卡号" />
                </a-form-model-item>

                <a-form-model-item ref="title" prop="title">
                    <a-input addon-before="论文题目:"  v-model="form.title" placeholder="论文题目" />
                </a-form-model-item>
                <a-form-model-item ref="author" prop="author">
                    <a-input addon-before="论文作者:"  v-model="form.author" placeholder="论文作者" />
                </a-form-model-item>
                <a-form-model-item ref="phone" prop="phone">
                    <a-input addon-before="手   机:"  v-model="form.phone" placeholder="手机号码" />
                </a-form-model-item>
            </a-form-model>
            <div class="file-upload">
                <ul class="tab">
                    <li>
                        上传文档
                        <span class="whiteline" style="display: block;"></span>
                    </li>
                </ul>
                <div class="upload-box">
                    <div class="pic">
                        <img src="@/assets/chooseword.png">
                    </div>
                    <div class="title">—— 支持 “DOC/DOCX/PDF” 格式，点击 “选择文件” 按钮上传论文 ——</div>
                    <div class="title">若文件选择错误，仅需重新选择文件即可覆盖。</div>
                    <a-upload
                        :multiple="false"
                        :file-list="fileList"
                        :customRequest="customRequest"
                        @change="handleChange"
                    >
                        <a-button>
                         选择文件
                        </a-button>
                    </a-upload>
                </div>
            </div>
            <div class="operation">
                <a-button type="primary" icon="upload" @click="onSubmit()" :loading="loading">
                    提交
                </a-button>
            </div>

            <a-modal title="信息" :dialog-style="{ top: '30px' }" :visible="modalVisible" @ok="() => setModalVisible(false)"
                @cancel="() => setModalVisible(false)">
                <a-descriptions>
                    <!-- <a-descriptions-item label="提交失败">{{ info.prompt }}</a-descriptions-item> -->
                    <a-descriptions-item label="已拍字符">{{ info.wordLimit }}</a-descriptions-item>
                    <a-descriptions-item label="当前文章字符数">{{ info.wordCount }}</a-descriptions-item>
                    <a-descriptions-item label="需要补拍字符数">{{ info.restWords }}</a-descriptions-item>
                    <a-descriptions-item label="提交失败">字符数超过提交限制，需要补2.7元商品{{ info.supplementNumber }}份</a-descriptions-item>
                </a-descriptions>
            </a-modal>

        </div>
        <GlobalFooter />
    </div>
</template>

<script>
import Navbar from "@/components/file/pc-header";
import SelectNavbar from "@/components/file/select-version"
import GlobalFooter from "@/components/file/footer"
import axios from "axios";


export default {
    components: {
        Navbar,
        GlobalFooter,
        SelectNavbar
    },
    data(){
        return{
            loading:false,
            labelCol: { span: 7 },
            wrapperCol: { span: 10 },
            form: {
                orderId: '',
                additionalOrderIds: [],
                title: '',
                author: '',
                phone: '',
            },
            modalVisible: false,
            info: {
                prompt: "",
                wordLimit: 0,
                wordCount: 0,
                restWords: 0,
                supplementNumber: 0
            },
            fileList: [],
            showAdd: false,
        }
    },
    methods:{
        changeShowAdd() {
            this.showAdd = !this.showAdd;
        },
        handleChange(info) {
            // console.log(info)
        },
        customRequest(data) {
            if (this.fileList.length != 0) {
                this.fileList.splice(0, 1, data.file)
            } else {
                this.fileList.push(data.file)
            }
        },

        addAdditionalOrderId() {
            this.form.additionalOrderIds.push({ value: '' });
        },
        removeAdditionalOrderId() {
            this.form.additionalOrderIds.pop();
        },

        async onSubmit() {
            if (this.form.orderId == "" || this.form.title == "" ||
                this.form.author == "" || this.fileList.length == 0) {
                this.$message.error('卡号，题目，作者，文件不能为空')
                return;
            }

            let formData = new FormData();
            formData.append("orderId", this.form.orderId);
            formData.append("title", this.form.title);
            formData.append("author", this.form.author);
            formData.append("phone", this.form.phone);
            formData.append("file", this.fileList[0]);
            for (let i of this.form.additionalOrderIds) {
                formData.append("additionalOrderId", i.value)
            }

            let url = `${this.weipu.serviceURL}/client/upload`;

            this.loading = true;

            axios({
                url,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                params: {},
                data: formData,
            }).then(async (res) => {
                if (res.data.code === -1) {
                    this.$message.error(res.data.message)
                    this.loading = false
                    return;
                } else if (res.data.code === -2) {
                    let message = JSON.parse(res.data.message)
                    this.info = message
                    this.modalVisible = true
                    this.loading = false
                    return;
                }
                sessionStorage.setItem('client-orderId-value', res.data.message)
                this.$router.push("/success-upload-loading");
            });
        },
        setModalVisible(modalVisible) {
            if (!modalVisible) {
                let info = {
                    prompt: "",
                    wordLimit: 0,
                    wordCount: 0,
                    restWords: 0,
                    supplementNumber: 0
                }
                this.info = info
            }
            this.modalVisible = modalVisible
        }
    }
}

</script>

<style lang="less" scoped>
.pc-client-upload {
    background-color: #F8F8F8;

    .content {
        max-width: 1200px;
        background: #fff;
        margin: 20px auto;
        padding: 0 116px 60px 116px;

        .content-title {
            margin-top: 38px;
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 22px;
        }

        .add-card {
            text-align: right;
            margin-bottom: 10px;
            margin-top: -10px;

            a {
                cursor: pointer;
            }
        }

        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .top-contet {
            .top-title {
                text-align: center;
                font-size: 22px;
                padding: 36px 0 30px 0;
                font-weight: 600;
                line-height: 42px;
            }
            
        }

        /deep/.ant-col-10 {
            width: 100%;

            .ant-input-wrapper {
                line-height: 46px;
                border: 1px solid #D2DBE6;
                overflow: hidden;
                position: relative;

                .ant-input {
                    line-height: 46px;
                    height: 46px;
                    border: 0 none;
                    box-sizing: border-box;
                    padding: 0 10px 0 10px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .ant-input-group-addon {
                    width: 80px;
                    border: none;
                    background: #fff;
                }

            }
        }

        .file-upload {
            ul {
                li {
                    display: inline-block;
                    width: 140px;
                    height: 46px;
                    text-align: center;
                    line-height: 45px;
                    border-left: 1px solid #D2DBE6;
                    border-top: 1px solid #D2DBE6;
                    border-right: 1px solid #D2DBE6;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: "";
                        width: 140px;
                        height: 3px;
                        position: absolute;
                        left: 0;
                        top: -1px;
                        background: #FF6565;
                    }

                    .whiteline {
                        content: "";
                        width: 140px;
                        height: 1px;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        background: #fff;
                        z-index: 99;
                    }
                }
            }

            .upload-box {
                height: 300px;
                box-sizing: border-box;
                border: 1px solid #D2DBE6;
                text-align: center;
                vertical-align: middle;

                .pic {
                    margin-top: 15px;

                    img {
                        height: 100px;
                        margin: 0 auto 10px auto;
                    }
                }

                .title {
                    height: 40px;
                    line-height: 24px;
                }

                /deep/.ant-btn {
                    border: 1px solid rgba(249, 65, 83, 1);
                    background: linear-gradient(315deg, rgba(249, 65, 83, 1) 0%, rgba(254, 105, 120, 1) 100%);
                    color: #FFF;
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                    border-radius: 4px;
                    font-size: 14px;
                }

                /deep/.ant-upload-list-item-info {
                    .ant-upload-list-item-name {
                        width: auto;
                    }

                    .ant-upload-list-item-card-actions {
                        display: none;
                    }
                }
            }
        }

    }

    .operation {
        padding-top: 35px;

        /deep/.ant-btn-primary {
            background-color: #FF311C;
            line-height: 48px;
            font-size: 16px;
            color: #fff;
            border-radius: 2px;
            height: 48px;
            width: 120px;
            border: none;

            .anticon-upload {
                display: none
            }

            span {
                margin-left: 0;
            }
        }
    }

    .addition-operation {

    }

}
</style>