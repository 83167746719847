<template>
    <div class="order-add">
        <a-layout class="content" style="min-height: 100vh">
            <SideBar />
            <a-layout>
                <Header />
                <a-breadcrumb style="margin: 16px 0">
                    <a-breadcrumb-item>查重树</a-breadcrumb-item>
                    <a-breadcrumb-item><a href="/#/user/main">订单主页</a></a-breadcrumb-item>
                    <a-breadcrumb-item>新建订单</a-breadcrumb-item>
                </a-breadcrumb>

                <div class="main" :style="{ padding: '24px', background: '#fff', minHeight: '1000px' }">
                    <div class="upload-list">
                        <a-table class="table" :columns="columns" :data-source="uploadList"
                            :scroll="{ x: 1500, y: 500 }" :rowKey="(record, index) => { return index }"
                            :pagination="false"></a-table>
                    </div>

                    <div class="dragger">
                        <a-upload-dragger name="file" :multiple="false" :customRequest="customRequest"
                            @change="handleChange" :file-list="fileList">
                            <p class="ant-upload-drag-icon">
                                <a-icon type="inbox" />
                            </p>
                            <p class="ant-upload-text">
                                点击或拖拽文件到此处
                            </p>
                            <p class="ant-upload-hint">
                                支持多个文件上传
                            </p>
                            <p class="ant-upload-hint">
                                仅支持doc, docx文件
                            </p>
                        </a-upload-dragger>
                    </div>

                    <div class="upload-button">
                        <a-button type="primary" icon="upload" :loading="buttonLoading" @click="onSubmit()">
                            提交
                        </a-button>
                    </div>


                </div>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import Header from '@/components/user/user-header'
import SideBar from '@/components/user/user-sidebar.vue'
import axiosFactory from "@/models/axios-factory";
import cookie from "@/common/cookie";

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            buttonLoading:false,
            fileList: [],
            uploadList: [],
            cacheList: [],
            columns: [
                {
                    title: '订单编号',
                    dataIndex: 'orderNo',
                    key: 'orderNo',
                },
                {
                    title: '订单id',
                    dataIndex: 'orderid',
                    key: 'orderid',
                },
                {
                    title: '文件名',
                    dataIndex: 'fileName',
                    key: 'fileName',
                },
                {
                    title: '订单状态',
                    dataIndex: 'status',
                    key: 'status',
                }
            ]
        }
    },
    methods: {
        handleChange(info) {
            if (this.matchFileSuffix(info.file.name) != "doc" &&
                this.matchFileSuffix(info.file.name) != "docx") {
                this.$message.error("仅支持doc, docx文件")
                return
            }

            if (this.checkExistFile(info.file.name, this.fileList)) {
                this.$message.error("重复文件名不可提交")
                return;
            }

            this.fileList = info.fileList
        },

        async onSubmit() {
            if (this.fileList.length == 0) {
                this.$message.error('文件为空')
                return;
            }

            this.buttonLoading = true;

            let url = `${this.weipu.proxyServiceURL}/chachong/upload`
            let userNumber = cookie.getCookie("number")
            for (let i of this.fileList) {
                let formData = new FormData();
                formData.append("file", i.originFileObj)
                formData.append("ageId", userNumber)
                formData.append("productId", 1)
                let submitResult = await axiosFactory.postFormData(url, formData);
                if (submitResult.code != 0) {
                    this.$message.error(submitResult.message);
                }
                if (submitResult.result.code == -1000) {
                    this.$router.push("/user/login")
                }
                let resultObj = {
                    orderid: submitResult.result.data.orderid,
                    orderNo: submitResult.result.data.orderNo,
                    fileName: i.name,
                    status: "已上传，正在处理"
                }
                let index = this.fileList.findIndex(item => item.name == i.name)
                this.fileList.splice(index, 1)
                this.uploadList.push(resultObj)
                this.cacheList.push(resultObj)
            }

            //处理缓存
            this.cacheListQueryParsing()

        },

        async cacheListQueryParsing() {
            let url = `${this.weipu.proxyServiceURL}/chachong/queryParsing`
            while (this.cacheList.length != 0) {
                await this.delay(1000)
                for (let item of this.cacheList) {
                    await this.delay(500)
                    let reqObj = {
                        orderId: item.orderid
                    }
                    let submitResult = await axiosFactory.get(url, reqObj)
                    if (submitResult.code != 0) {
                        this.$message.error(submitResult.message);
                    }
                    if (submitResult.result.code == -1000) {
                        this.$router.push("/user/login")
                    }
                    if (submitResult.result.code != 1003) {
                        this.uploadList.forEach(data=>{
                            if(data.orderid == item.orderid){
                                data.status = submitResult.result.msg
                            }
                        })
                        let index = this.cacheList.findIndex(data => data.orderid == item.orderid)
                        this.cacheList.splice(index, 1)
                    }
                }
            }
        },

        async delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        checkExistFile(fileName) {
            for (let i of this.fileList) {
                if (fileName == i.name) {
                    return true
                }
            }
            for (let i of this.uploadList) {
                if (fileName == i.fileName) {
                    return true
                }
            }
            return false
        },

        customRequest(data) {
            // this.fileList.push(data.file)
        },
        matchFileSuffix(value) {
            let fileSuffix = value.match(/\.([^.]+)$/)[1]
            return fileSuffix
        }

    }
}
</script>

<style lang="less">
.order-add {
    .content {
        .main {
            .dragger {
                padding-top: 30px;
                height: 200px;
                width: 500px;
            }

            .upload-button {
                padding-top: 40px;
                text-align: center;
                // float: right;
            }
        }
    }
}
</style>