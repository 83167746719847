<template>
    <div class="client-upload">
        <Navbar />
        <div class="content">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                <a-form-model-item label="检测卡号" ref="orderId" prop="orderId">
                    <a-input v-model="form.orderId" placeholder="淘宝商家提供的检测卡号" />
                </a-form-model-item>

                <div class="add-card" @click="changeShowAdd">
                    <a>补拍点这里</a>
                </div>  

                <a-form-model-item class="addition-operation" v-show="showAdd">
                    <a-button @click="addAdditionalOrderId" icon="plus-circle" type="primary">添加补拍卡号</a-button>
                    <a-button @click="removeAdditionalOrderId" icon="minus-circle" type="primary" style="margin-left: 8px;">删除补拍卡号</a-button>
                </a-form-model-item>

                <!-- 动态添加的输入框 -->
                <a-form-model-item label="新增补拍卡号" v-for="(id, index) in form.additionalOrderIds" :key="index">
                    <a-input v-model="id.value" placeholder="请输入补拍卡号" />
                </a-form-model-item>

                <a-form-model-item label="论文题目" ref="title" prop="title">
                    <a-input v-model="form.title" placeholder="论文题目" />
                </a-form-model-item>
                <a-form-model-item label="论文作者" ref="author" prop="author">
                    <a-input v-model="form.author" placeholder="论文作者" />
                </a-form-model-item>
                <a-form-model-item label="手机" ref="phone" prop="phone">
                    <a-input v-model="form.phone" placeholder="手机号码" />
                </a-form-model-item>
            </a-form-model>
            <a-upload-dragger name="file" :multiple="false" :customRequest="customRequest" @change="handleChange"
                :file-list="fileList">
                <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                    点击或拖拽文件到此处
                </p>
                <p class="ant-upload-hint">
                    仅支持单个文件上传
                </p>
                <p class="ant-upload-hint">
                    仅支持doc, docx, pdf文件
                </p>
                <p class="ant-upload-hint">
                    若文件上传错误，仅需重新点击上传或拖拽到此处即可覆盖。
                </p>
            </a-upload-dragger>
            <div class="operation">
                <a-button type="primary" icon="upload" @click="onSubmit()" :loading="loading">
                    提交
                </a-button>
            </div>

            <a-modal title="信息" :dialog-style="{ top: '30px' }" :visible="modalVisible" @ok="() => setModalVisible(false)"
                @cancel="() => setModalVisible(false)">
                <a-descriptions>
                    <a-descriptions-item label="提交失败">{{ info.prompt }}</a-descriptions-item>
                    <a-descriptions-item label="字符限制">{{ info.wordLimit }}</a-descriptions-item>
                    <a-descriptions-item label="当前订单字符数">{{ info.wordCount }}</a-descriptions-item>
                    <a-descriptions-item label="需要补单字符数量">{{ info.restWords }}</a-descriptions-item>
                    <a-descriptions-item label="需要补2.7元千字卡密数量">{{ info.supplementNumber }}</a-descriptions-item>
                </a-descriptions>
            </a-modal>

        </div>
        <GlobalFooter />
    </div>
</template>

<script>
import Navbar from "@/components/file/header";
import GlobalFooter from "@/components/file/footer"
import axios from "axios";


export default {
    components: {
        Navbar,
        GlobalFooter,
    },

    data() {
        return {
            loading:false,
            labelCol: { span: 7 },
            wrapperCol: { span: 10 },
            form: {
                orderId: '',
                additionalOrderIds: [],
                title: '',
                author: '',
                phone: '',
            },
            modalVisible: false,
            info: {
                prompt: "",
                wordLimit: 0,
                wordCount: 0,
                restWords: 0,
                supplementNumber: 0
            },
            fileList: [],
            rules: {
                orderId: [{ required: true, message: '请输入检测卡号', trigger: 'blur' }],
                title: [{ required: true, message: '请输入论文题目', trigger: 'blur' }],
                author: [{ required: true, message: '请输入论文作者', trigger: 'blur' }]
            },
            showAdd: false,
        };
    },
    methods: {

        changeShowAdd() {
            this.showAdd = !this.showAdd;
        },

        handleChange(info) {
            // console.log(info)
        },
        customRequest(data) {
            // console.log(data)
            if (this.fileList.length != 0) {
                this.fileList.splice(0, 1, data.file)
            } else {
                this.fileList.push(data.file)
            }
        },

        addAdditionalOrderId() {
            this.form.additionalOrderIds.push({ value: '' });
        },
        removeAdditionalOrderId() {
            this.form.additionalOrderIds.pop();
        },

        async onSubmit() {
            if (this.form.orderId == "" || this.form.title == "" ||
                this.form.author == "" || this.fileList.length == 0) {
                this.$message.error('卡号，题目，作者，文件不能为空')
                return;
            }

            let formData = new FormData();
            formData.append("orderId", this.form.orderId);
            formData.append("title", this.form.title);
            formData.append("author", this.form.author);
            formData.append("phone", this.form.phone);
            formData.append("file", this.fileList[0]);
            for (let i of this.form.additionalOrderIds) {
                formData.append("additionalOrderId", i.value)
            }

            let url = `${this.weipu.serviceURL}/client/upload`;

            this.loading = true;

            axios({
                url,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                params: {},
                data: formData,
            }).then(async (res) => {
                if (res.data.code === -1) {
                    this.$message.error(res.data.message)
                    this.loading = false
                    return;
                } else if (res.data.code === -2) {
                    let message = JSON.parse(res.data.message)
                    this.info = message
                    this.modalVisible = true
                    this.loading = false
                    return;
                }
                sessionStorage.setItem('client-orderId-value', res.data.message)
                this.$router.push("/success-upload-loading");
            });
        },
        setModalVisible(modalVisible) {
            if (!modalVisible) {
                let info = {
                    prompt: "",
                    wordLimit: 0,
                    wordCount: 0,
                    restWords: 0,
                    supplementNumber: 0
                }
                this.info = info
            }
            this.modalVisible = modalVisible
        }
    },
};

</script>

<style lang="less">
.client-upload {
    .content {
        padding-top: 20px;
    }

    .add-card {
            text-align: right;
            margin-bottom: 10px;
            margin-top: -10px;

            a {
                cursor: pointer;
            }
        }

    .operation {
        padding-top: 20px;
        text-align: center;
    }

    .addition-operation {
        // text-align: center;
    }

}
</style>