<template>
    <div class="client-file-manage-header">
        <div class="content-header">
            <div class="logo-container" >
                <LogoContainer />
            </div>
           <div class="memu-btn">
                <span :class="{'active': current=='upload'}"  @click="goto('upload')">提交检测</span>
                <span :class="{'active': current=='download'}" @click="goto('download')">报告下载</span>
          </div> 
        </div>
    </div>
</template>

<script>
import LogoContainer from '@/components/file/logo-container'

export default {
    components:{
        LogoContainer,
    },

    data() {
        return {
            current: ""
        }
    },

    mounted() {
        // console.log(this.$route.path)
        if (this.$route.path == '/upload' || this.$route.path == '/') {
            this.current = 'upload';
            this.logo = true
        } else if (this.$route.path == '/download') {
            this.current = 'download'
            this.logo = true
        }
    },

    created() {
       
    },

    methods: {
        goto(val) {
            switch (val) {
                case "upload":
                    if(this.current !== "upload") {
                        this.$router.push("/");  
                    }
                    break;
                case "download":
                    if(this.current !== "download") {
                        this.$router.push("/download");
                    }
                    break;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.client-file-manage-header {
    background: #fff;
    .content-header {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
    }
    .memu-btn {
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        padding: 0 40px;

        span {
            padding: 0 16px;
            font-weight: 600;
            color: #333;
        }
        span.active {
            color: #FF311C;
        }
    }
    .logo-container {
        
    }

    .ads-container {
        
    }


}
</style>