import Vue from 'vue'
import Router from 'vue-router'
import ClientUpload from '@/modules/file/client-upload'
import PcClientUpload from '@/modules/file/client-upload-pc'
import ClientDownload from '@/modules/file/client-download'
import SuccessUploadLoading from '@/modules/file/success-upload-loading'
import UserLogin from '@/modules/user/login'
import ChachongMain from '@/modules/user/main'
import ChachongOrderAdd from '@/modules/user/order-add'
import ChachongBalance from '@/modules/user/balance'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'upload',
            component: window.innerWidth <= 750 ? ClientUpload : PcClientUpload
        },
        {
            path: '/upload',
            name: 'upload',
            component: window.innerWidth <= 750 ? ClientUpload : PcClientUpload
        },
        {
            path:'/success-upload-loading',
            name:'upload-loading',
            component:SuccessUploadLoading
        },
        {
            path:'/download',
            name:'download',
            component:ClientDownload
        },
        {
            path:'/user/login',
            name:'user-check-login',
            component:UserLogin
        },
        {
            path:'/user/main',
            name:'user-main',
            component:ChachongMain
        },
        {
            path:'/user/order/add',
            name:'user-order-add',
            component:ChachongOrderAdd
        },
        {
            path:'/user/balance',
            name:'user-balance',
            component:ChachongBalance
        }
    ]
})