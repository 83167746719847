<template>
    <div class="select-version-content">
        <h1 class="top-title">可选择的检测版本</h1>
                <ul class="select-version">
                   <li class="version" @click="onShowCollege">
                    <a v-show="showCollege">
                        <span>
                            <img style="margin-top:15px;" src="@/assets/click.png">
                        </span>
                    </a>
                    <img src="@/assets/bachelor.png">
                   </li> 

                   <li class="version" @click="onShowGraduate">
                    <a v-show="showGraduate">
                        <span>
                            <img style="margin-top:15px;" src="@/assets/click.png">
                        </span>
                    </a>
                    <img src="@/assets/master.png">
                   </li> 

                   <li class="version" @click="onShowEditor">
                    <a v-show="showEditor">
                        <span>
                            <img style="margin-top:15px;" src="@/assets/click.png">
                        </span>
                    </a>
                    <img src="@/assets/editor.png">
                   </li> 


                   <li class="version" @click="onShowTitle">
                    <a v-show="showTitle">
                        <span>
                            <img style="margin-top:15px;" src="@/assets/click.png">
                        </span>
                    </a>
                    <img src="@/assets/title.png">
                   </li>

                </ul>
                <ul class="version-tip" v-show="showCollege || showGraduate || showEditor || showTitle">
                   <li class="version-dec clearfix"  v-show="showCollege">
                        <b>维普大学生版</b>：适用大中专、本科阶段的毕业生。
                        <h6 class="sanjiao"></h6>
                   </li> 
                   <li class="version-dec clearfix" v-show="showGraduate">
                        <b>维普研究生版</b>：适用硕士、博士论文。
                        <h6 class="sanjiao"></h6>
                   </li> 
                   <li class="version-dec clearfix" v-show="showEditor">
                        <b>维普编辑部版</b>：适用期刊、图书、会议论文。
                        <h6 class="sanjiao"></h6>
                   </li> 
                   <li class="version-dec clearfix" v-show="showTitle">
                        <b>维普职称版</b>：适用职称评审论文、已发表论文。
                        <h6 class="sanjiao"></h6>
                   </li> 
                </ul>
    </div>
</template>

<script>

export default{
    data(){
        return{
            showCollege: false,
            showGraduate: false,
            showEditor:false,
            showTitle:false
        }
    },
    methods:{
        onShowCollege() {
            this.showCollege = true;
            this.showGraduate = false;
            this.showEditor = false;
            this.showTitle = false;
        },

        onShowGraduate() {
            this.showGraduate = true;
            this.showCollege = false;
            this.showEditor = false;
            this.showTitle = false;
        },

        onShowEditor() {
            this.showEditor = true;
            this.showCollege = false;
            this.showGraduate = false;
            this.showTitle = false;
        },

        onShowTitle(){
            this.showTitle = true;
            this.showEditor = false;
            this.showCollege = false;
            this.showGraduate = false;
        }
    }
}

</script>

<style lang="less">
.select-version-content{
    ul,li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    .top-title {
                text-align: center;
                font-size: 22px;
                padding: 36px 0 30px 0;
                font-weight: 600;
                line-height: 42px;
            }
            .select-version {
                display: flex;
                .version {
                    border-radius: 4px;
                    position: relative;
                    margin-right: 36px;
                    height: 97px;
                    cursor: pointer;
                    a {
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url("~@/assets/blackdot.png") repeat;
                        width: 215px;
                        height: 97px;
                        text-align: center;
                        span {
                            width: 58px;
                            height: 58px;
                            border-radius: 58px;
                            background-color: #fff;
                            display: inline-block;
                            text-align: center;
                            margin-top: 18px;
                        }
                    }
                    > img {
                        width: 214px;
                        height: auto;
                    }
                }
            }

            .version-tip {
                .version-dec {
                    color: #1272D7;
                    line-height: 48px;
                    background-color: #F0F7FF;
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-top: 22px;
                    position: relative;
                    .sanjiao {
                        position: absolute;
                        left: 100px;
                        top: -10px;
                        width: 0;
                        height: 0;
                        border-bottom: 10px solid #F0F7FF;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }
                }
            }
           
}
</style>