<template>
<a-result
    status="success"
    title="订单提交成功"
    sub-title="自动跳转至订单下载页，请稍等......."
  >
  <template #extra>
     <a-spin tip="Loading...">
      <div class="spin-content">
      </div>
    </a-spin>
  </template>
  </a-result>
</template>

<script>
export default{
    mounted(){
        (async ()=>{
            await this.timeout(3000);
            this.$router.push('/download')
        })()
    },

    methods:{
        timeout(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }
}
</script>

<style lang="less">
</style>