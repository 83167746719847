<template>
 <div class="user-balance">
        <a-layout class="content" style="min-height: 100vh">
            <SideBar />
            <a-layout>
                <Header />
                <a-breadcrumb style="margin: 16px 0">
                        <a-breadcrumb-item>查重树</a-breadcrumb-item>
                        <a-breadcrumb-item>余额查询</a-breadcrumb-item>
                </a-breadcrumb>

                <div class="main" :style="{ padding: '24px', background: '#fff', minHeight: '500px' }">
                    当前余额：{{this.balance}}
                </div>

            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import Header from '@/components/user/user-header'
import SideBar from '@/components/user/user-sidebar.vue'
import axiosFactory from "@/models/axios-factory";

export default {
    components: {
        Header,
        SideBar
    },

    mounted(){
        this.getBalance()
    },

    data(){
        return{
            balance:''
        }
    },
    methods:{
        async getBalance(){
            let data = await axiosFactory.get(
                `${this.weipu.proxyServiceURL}/chachong/getBalance`
            )
            // console.log(data.result.balance)
            this.balance = data.result.balance
        }
    }
}
</script>

<style lang="less">
.user-main {
    .content {
        .main{
            
        }
    }
}
</style>