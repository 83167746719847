<template>
    <a-layout-sider v-model="collapsed" collapsible >
          <a-menu theme="dark" :default-selected-keys="selectedKey" mode="inline">
            <a-menu-item key="1" @click="goto('main')">
              <a-icon type="desktop" />
              <span>订单主页</span>
            </a-menu-item>
            <a-menu-item key="2" @click="goto('balanceCheck')">
              <a-icon type="search" />
              <span>余额查询</span>
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
</template>

<script>
export default {

  mounted(){
    this.getSelectKey()
  },  
  data() {
    return {
      collapsed: false,

      selectedKey:['1'],

      menuList:{
        main:{
            route:"/user/main",
            key:"1"
        },
        balanceCheck:{
            route:"/user/balance",
            key:"2"
        }
      }

    };
  },
  methods:{
    goto(val){
        sessionStorage.setItem("menu-active-value",this.menuList[val].key);
        this.$router.push(this.menuList[val].route)
    },

    getSelectKey(){
        let activeValue = sessionStorage.getItem("menu-active-value");
        this.selectedKey[0] = activeValue
    }

  }

};
</script>

<style lang="less">
</style>