<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  .app {
    width: 100%;
    height: 100%;
  }
}
</style>
