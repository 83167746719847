import "babel-polyfill"
import 'classlist-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import weipu from './config'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

Vue.prototype.weipu = weipu

Vue.prototype.$openLink = function (url) {
  window.open(url, '_blank');
};

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
