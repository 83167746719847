<template>
    <a-layout-header class="user-header" style="background:#fff; padding: 0;">
        <a class="logout" href="#" @click="logout()">登出</a>
    </a-layout-header>
</template>

<script>
import axiosFactory from '@/models/axios-factory'
import cookie from '@/common/cookie'

export default{

    data(){
        return{

        }
    },

    methods:{
        async logout(){
            cookie.delCookie("token");
            this.$router.push("/user/login");
        }
    }
}

</script>

<style lang="less">
.user-header {
    .message {
        padding-left: 30px;
    }

    .logout {
        float: right;
        padding-right: 10px;
    }
}
</style>