<template>
    <div class="user-main">
        <a-layout class="content" style="min-height: 100vh">
            <SideBar />
            <a-layout>
                <Header />
                <a-breadcrumb style="margin: 16px 0">
                    <a-breadcrumb-item>查重树</a-breadcrumb-item>
                    <a-breadcrumb-item>订单主页</a-breadcrumb-item>
                </a-breadcrumb>

                <div class="main" :style="{ padding: '24px', background: '#fff', minHeight: '1000px' }">
                    <a-button type="primary" icon="plus" @click="addOrder()">添加</a-button>
                    <div class="top-control">
                        <a-row class="selection">
                            <a-col :span="2">处理状态:</a-col>
                            <a-col :span="5">
                                <a-select default-value="" style="width: 120px" v-model="handle_status">
                                    <a-select-option value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option value="0">
                                        等待检测
                                    </a-select-option>
                                    <a-select-option value="1">
                                        检测完成
                                    </a-select-option>
                                    <a-select-option value="2">
                                        正在检测
                                    </a-select-option>
                                    <a-select-option value="3">
                                        检测失败
                                    </a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                        <a-row class="time-period">
                            <a-col :span="6">时间区间（默认时间区间为当天）：</a-col>
                            <a-range-picker v-model="time_period" :show-time="{ format: 'HH:mm' }"
                                format="YYYY-MM-DD HH:mm" :placeholder="['开始时间', '结束时间']" @change="onChange"
                                @ok="onOk" />
                        </a-row>
                        <a-row class="input">
                            <a-col :span="5">
                                <a-input v-model="id" placeholder="订单id" />
                            </a-col>
                            <a-col :span="2"></a-col>
                            <a-col :span="5">
                                <a-input v-model="order_no" placeholder="订单编号" />
                            </a-col>
                        </a-row>
                        <a-row class="search">
                            <a-col :span="5">
                                <a-button type="primary" icon="search" @click="search()">
                                    搜索
                                </a-button>
                            </a-col>
                        </a-row>

                        <a-row class="download">
                            <a-col :span="5">
                                <a-button type="primary" icon="download" @click="downloadReport()">下载报告</a-button>
                            </a-col>
                            <a-col :span="5">
                                <a-button type="primary" icon="download" @click="downloadFile()">下载原文</a-button>
                            </a-col>
                            <a-col :span="5">
                                <a-button type="primary" icon="check" @click="parseData()">解析</a-button>
                            </a-col>
                        </a-row>

                    </div>

                    <a-table class="table" :columns="columns" :data-source="tableData" :scroll="{ x: 1500, y: 500 }"
                        :rowKey="(record, index) => { return index }" :pagination="false"
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"></a-table>
                    <a-pagination class="pagination" v-model="pageIndex" :total="totalNum" @change="pageChange" />
                </div>

            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import Header from '@/components/user/user-header'
import SideBar from '@/components/user/user-sidebar.vue'
import axiosFactory from "@/models/axios-factory";

export default {
    components: {
        Header,
        SideBar
    },

    mounted() {
        this.setDefaultDatePeriod()
        this.render()
    },


    data() {
        return {
            handle_status: '',
            time_period: [],
            period: '',
            id: '',
            order_no: '',
            tableData: [],
            selectedRowKeys: [],
            pageIndex: 1,
            totalNum: 0,
            size: 10,
            columns: [
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    width: 100,
                    fixed: 'left'
                },
                {
                    title: '作者',
                    dataIndex: 'author',
                    key: 'author',
                    width: 100,
                    fixed: 'left'
                },
                {
                    title: '订单编号',
                    dataIndex: 'order_no',
                    key: 'order_no',
                },
                {
                    title: '订单id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '字数',
                    dataIndex: 'word_count',
                    key: 'word_count',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '数量',
                    dataIndex: 'num',
                    key: 'num',
                },
                {
                    title: '支付状态',
                    dataIndex: 'pay_status',
                    key: 'pay_status',
                },
                {
                    title: '支付金额',
                    dataIndex: 'order_money',
                    key: 'order_money',
                },
                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '订单状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: '处理状态',
                    dataIndex: 'handle_status',
                    key: 'handle_status',
                },
                {
                    title: '处理备注',
                    dataIndex: 'handle_comments',
                    key: 'handle_comments',
                },
            ]
        }
    },

    methods: {

        async parseData() {
            let url = `${this.weipu.proxyServiceURL}/chachong/queryParsing`
            for (let i of this.selectedRowKeys) {
                let id = this.tableData[i].id
                let reqObj = {
                    orderId: id
                }
                let data = await axiosFactory.get(url, reqObj)
                if (data.code != 0) {
                    this.$message.error(submitResult.message);
                }
                if (data.result.code == -1000) {
                    this.$router.push("/user/login")
                }

                if(data.result.code == 1003){
                    this.$message.info("订单号："+id+" "+"状态："+data.result.msg)
                }else if(data.result.code == 1001){
                    this.$message.error("订单号："+id+" "+"状态："+data.result.msg)
                }else{
                    this.$message.success("订单号："+id+" "+"状态："+data.result.msg)
                }
            }
            this.render()
        },


        async downloadReport() {
            if (this.selectedRowKeys.length > 1 || this.selectedRowKeys.length == 0) {
                this.$message.error("请选择一条数据");
                return;
            }

            let order = this.tableData[this.selectedRowKeys[0]]
            let orderId = order.id
            let reqObj = {
                ids: orderId,
                method: "download-report",
                ispopup: true,
                oli_submit: true
            }

            let data = await axiosFactory.post(`${this.weipu.proxyServiceURL}/chachong/downloadOrder`, reqObj)
            if (data.code != 0) {
                this.$message.error(data.message);
                return;
            }
            if (data.result.code == -1000) {
                this.$router.push("/user/login")
            }
            if (data.result.code != 0) {
                this.$message.error(data.result.msg);
                return;
            }
            console.log(data.result.data.url)
            // let httpsURL = data.result.data.url.replace('http','https')
            // window.location.href = httpsURL
            window.location.href = data.result.data.url

        },

        async downloadFile() {
            if (this.selectedRowKeys.length > 1 || this.selectedRowKeys.length == 0) {
                this.$message.error("请选择一条数据");
                return;
            }

            let order = this.tableData[this.selectedRowKeys[0]]
            let orderId = order.id
            let reqObj = {
                ids: orderId,
                method: "download-original",
                ispopup: true,
                oli_submit: true
            }

            let data = await axiosFactory.post(`${this.weipu.proxyServiceURL}/chachong/downloadOrder`, reqObj)
            if (data.code != 0) {
                this.$message.error(data.message);
                return;
            }
            if (data.result.code == -1000) {
                this.$router.push("/user/login")
            }
            if (data.result.code != 0) {
                this.$message.error(data.result.msg);
                return;
            }

            let httpsURL = data.result.data.url.replace('http','https')
            window.location.href = httpsURL

        },

        onSelectChange(selectedRowKeys) {
            console.log(selectedRowKeys)
            //console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },

        addOrder() {
            this.$router.push("/user/order/add");
        },

        search() {
            if (this.id != '' || this.order_no != '') {
                this.period = ''
                this.time_period = []
            }
            this.render()
        },

        async render() {
            let reqObj = {
                page: this.pageIndex,
                size: this.size,
                handle_status: this.handle_status,
                period: this.period,
                id: this.id,
                order_no: this.order_no
            }
            let data = await axiosFactory.get(`${this.weipu.proxyServiceURL}/chachong/orderList`, reqObj)
            if (data.code != 0) {
                this.$message.error(data.message);
                return;
            }
            if (data.result.code == -1000) {
                this.$router.push("/user/login")
            }
            if (data.result.code != 0) {
                this.$message.error(data.result.msg);
                return;
            }

            this.totalNum = data.result.count
            this.tableData = data.result.data
        },

        setDefaultDatePeriod() {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();
            const currentDate = yyyy + '-' + mm + '-' + dd;
            this.period = currentDate + ' 00:00:00 ' + '-' + ' ' + currentDate + ' ' + '23:59:59'
        },

        pageChange(val) {
            this.pageIndex = val;
            this.render();
        },

        onChange(value, dateString) {
            console.log('Selected Time: ', value);
            if (value.length === 0) {
                this.period = []
                this.setDefaultDatePeriod()
            } else {
                this.period = dateString[0] + " - " + dateString[1]
            }
        },
        onOk(value) {
            //console.log('onOk: ', value);
            console.log(this.period)
        },
    }

}
</script>

<style lang="less">
.user-main {
    .content {
        .main {
            .top-control {
                padding-top: 20px;

                .selection {}

                .time-period {
                    padding-top: 10px;
                }

                .input {
                    padding-top: 10px;
                }

                .search {
                    padding-top: 10px;
                    padding-bottom: 20px;
                }

            }

            .table {
                padding-top: 30px;
            }

            .pagination {
                padding-top: 30px;
                text-align: center;
            }

        }
    }
}
</style>