<template>
    <div class="client-file-manage-header">
        <div class="logo-container" v-if="logo">
            <LogoContainer />
        </div>
        <a-menu v-model="current" mode="horizontal" class="menu">
            <a-menu-item key="upload" @click="goto('upload')">
                <a-icon type="upload" /> 提交检测
            </a-menu-item>
            <a-menu-item key="download" @click="goto('download')">
                <a-icon type="download" />报告下载
            </a-menu-item>
            
        </a-menu>
    </div>
</template>

<script>
import LogoContainer from '@/components/file/logo-container'

export default {

    components:{
        LogoContainer,
    },

    data() {
        return {
            logo: null,
            ads: null,
            // mobile: null,
            // mobile_ads:null,
            windowWidth: null,
            current: []
        }
    },

    mounted() {
        // console.log(this.$route.path)
        if (this.$route.path == '/upload' || this.$route.path == '/') {
            this.current = ['upload']
            this.logo = true
        } else if (this.$route.path == '/download') {
            this.current = ['download']
            this.logo = true
        } else {
            this.current = ['reduction']
            this.ads = true
        }
    },

    created() {
        window.addEventListener("resize", this.checkScreen);
        this.checkScreen();
    },

    methods: {
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750) {
                this.mobile = true;
                //this.mobile_ads = true
                return;
            }
            this.mobile = false;
           // this.mobile_ads = false;
            return;
        },

        goto(val) {
            switch (val) {
                case "upload":
                    this.$router.push("/");
                    break;
                case "download":
                    this.$router.push("/download");
                    break;
                case "reduction":
                    this.$router.push("/reduction")
                    break;
            }
        }
    }
}
</script>

<style lang="less">
.client-file-manage-header {

    .logo-container {
        
    }

    .ads-container {
        
    }


}
</style>